.AppContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AppContent-header {
  padding: 30px;
}

.AppContent-formWrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 15px 20px;
}

.AppContent-errorMessage {
  text-align: center;
  padding-top: 30px;
  color: #b70000;
  max-width: 700px;
}

.AppContent-info {
  padding-top: 20px;
  text-align: center;
}

.AppContent-message {
  padding-bottom: 30px;
}

.LoggedInInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
