@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&family=Source+Sans+Pro&display=swap");
@import "components/app-content.css";
@import "./components/form.css";

html {
  background: #fff;
  color: #000;
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

html, body, #root {
  margin: 0;
}

h1 {
  font-size: 28px;
  font-family: "Raleway", "Arial", sans-serif;
  font-weight: 700;
}

a {
  color: #000;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.App-logo {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  width: 150px;
}

.App-introImage {
  max-width: 700px;
}

.Button {
  background: #BFA46E;
  font-family: "Source Sans Pro", "Arial", sans-serif;
  padding: 1px 20px;
  color: #fff;
  border: 2px solid #BFA46E;
  border-radius: 100px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  font-size: 16px;
  margin: 2px;
}

.Button:hover {
  background: none;
  color: #BFA46E;
}

.Button:focus {
  outline: none;
}

.Button:disabled {
  color: #d6d6d6;
}

.Button:disabled:hover {
  background: #BFA46E;
  cursor: default;
}
