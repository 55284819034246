.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form-description {
  padding-bottom: 10px;
  margin: 0;
}

.Form-inputsWrapper {
  display: grid;
  grid-template-columns: 60px 140px;
  grid-gap: 5px;
}

.Form-input {
  width: 100%;
  padding: 3px 10px;
  box-sizing: border-box;
  border: 1px solid #000;
  background: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-appearance: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  margin: 20px 0 25px;
  border-radius: 0;
}

.Form-input:focus {
  outline: none;
}

.Form-input:disabled {
  border-color: gray;
}

.Form-countryCodeInput {
  text-align: center;
}

.Form-codeInput {
  width: 160px;
  text-align: center;
}

.Form-input::placeholder, .Form-input:-ms-input-placeholder, .Form-input::-ms-input-placeholder {
  color: #999;
  opacity: 1;
}
